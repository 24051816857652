<template>
  <v-row>
    <v-col cols="5">
      <validation-observer ref="report" v-slot="{ passes }">
        <v-form @submit.prevent="passes( submit )">
          <v-card flat class="pa-4">
            <v-card-title class="d-block px-0">
              <back-button-component/>
              <div class="d-flex">
                <span class="font-weight-bold d-block">Edit AHA Report dated {{ form.date }}</span>
              </div>
            </v-card-title>
            <v-card class="rounded-lg" >
              <v-card-text>
                <validation-provider name="day rate" rules="min_value:0" v-slot="{ errors }">
                  <v-row>
                    <v-col cols="12" class="mb-4">
                      <label class="font-weight-bold">How do you rate your day?</label>
                    </v-col>
                    <v-col cols="12">
                      <v-slider
                          v-model="form.day_rate"
                          :error-messages="errors"
                          step="1"
                          thumb-label
                          min="0"
                          max="10"
                          ticks
                      >
                        <template v-slot:prepend>
                          <small class="font-weight-thin">Bad day</small>
                        </template>
                        <template v-slot:append>
                          <small class="font-weight-thin">Good day</small>
                        </template>
                      </v-slider>
                    </v-col>
                  </v-row>
                </validation-provider>
                <validation-provider name="description" rules="required" v-slot="{ errors }">
                  <v-row>
                    <v-col cols="12">
                      <v-textarea v-model="form.description"
                                  :error-messages="errors"
                                  persistent-placeholder
                                  placeholder="Please, fill here your opinion"
                      >
                        <template v-slot:label>
                          <label class="font-weight-bold">What was a life-lesson you learned?</label>
                        </template>
                      </v-textarea>
                    </v-col>
                  </v-row>
                </validation-provider>
                <validation-provider name="day effort" rules="min_value:0"  v-slot="{ errors }">
                  <v-row>
                    <v-col cols="12" class="mb-4">
                      <label class="font-weight-bold">How do you rate your effort today?</label>
                    </v-col>
                    <v-col cols="12">
                      <v-slider
                          v-model="form.effort_rate"
                          :error-messages="errors"
                          step="1"
                          thumb-label
                          min="0"
                          max="10"
                          ticks
                      >
                        <template v-slot:prepend>
                          <small class="font-weight-thin">Bad day</small>
                        </template>
                        <template v-slot:append>
                          <small class="font-weight-thin">Good day</small>
                        </template>
                      </v-slider>
                    </v-col>
                  </v-row>
                </validation-provider>
                <validation-provider name="note" rules="required" v-slot="{ errors }">
                  <v-row>
                    <v-col cols="12">
                      <v-textarea v-model="form.notice"
                                  :error-messages="errors"
                                  persistent-placeholder
                                  placeholder="Please, write here your thoughts"
                      >
                        <template v-slot:label>
                          <label class="font-weight-bold">Note</label>
                        </template>
                      </v-textarea>
                    </v-col>
                  </v-row>
                </validation-provider>
              </v-card-text>
              <v-card-actions class="pb-8 px-4">
                <v-spacer/>
                <v-btn width="89" height="49" rounded
                       class="d-flex align-center font-weight-bold"
                       color="secondary" type="button">
                  <span class="text-capitalize">Cancel</span>
                </v-btn>
                <v-btn width="98" height="49" rounded class="d-flex align-center font-weight-bold"
                       color="primary" type="submit">
                  <span class="text-capitalize">Update</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card>
        </v-form>
      </validation-observer>
    </v-col>
    <v-col cols="7">
      <report-data-table-component/>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Edit",
  data () {
    return {
      form: {}
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route (to, from) {
      this.getReport()
    }
  },
  mounted() {
    this.getReport()
  },
  methods: {
    getReport () {
      this.$http.get(`${process.env.VUE_APP_API_URL}/reports/${this.$route.params.id}`)
          .then((response) => {
            this.form = response.data
          })
    },
    submit () {
      this.$http.patch(`${process.env.VUE_APP_API_URL}/reports/${this.$route.params.id}`, this.form)
          .then(() => {
            this.$refs.report.reset()
            this.$root.$emit('getReports')
          })
    },
  }
}
</script>

<style scoped>

</style>